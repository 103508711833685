// Navigation Menu Vertical

.vertical-nav-menu {
    position: relative;

    &::after {
        content: ' ';
        pointer-events: none;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
    }
    .metismenu-container {
        list-style: none;

        &:before {
            opacity: 0;
            transition: opacity 300ms;
        }
        .metismenu-link {
            transition: transform 0.2s, height 300ms, color 300ms,
                background-color 300ms;
            display: block;
            line-height: 2.5rem;
            height: 2.5rem;
            padding: 0 $layout-spacer-x 0 45px;
            position: relative;
            @include border-radius($border-radius);
            color: $gray-800;
            white-space: nowrap;

            &.support-link {
                color: white !important;
            }
            &.selected {
                cursor: progress;
            }

            &.next {
                text-decoration: none;
                color: black !important;
                transform: scale(1.02);

                i.metismenu-icon {
                    opacity: 0.6;
                }
                i.metismenu-state-icon {
                    opacity: 1;
                }
            }

            &:hover {
                text-decoration: none;
                color: black !important;
                transform: scale(1.02);

                i.metismenu-icon {
                    opacity: 0.6;
                }
                i.metismenu-state-icon {
                    opacity: 1;
                }
            }

            &.active {
                color: black !important;
                background-color: #7f94c7 !important;
            }
        }
        i.metismenu-state-icon,
        i.metismenu-icon {
            text-align: center;
            width: 34px;
            height: 34px;
            line-height: 34px;
            position: absolute;
            left: 5px;
            top: 50%;
            margin-top: -17px;
            font-size: 1.5rem;
            opacity: 0.3;
            transition: color 300ms;
        }

        i.metismenu-state-icon {
            transition: transform 300ms;
            left: auto;
            right: 0;

            &.rotate-minus-90 {
                transform: rotate(-180deg);
            }
        }

        .metismenu-container {
            transition: padding 300ms;

            &.visible {
                padding: 0.5em 0 0 2rem;

                &:before {
                    content: '';
                    height: 100%;
                    opacity: 1;
                    width: 2px;
                    position: absolute;
                    left: 0.35rem;
                    top: 0;
                    @include border-radius(15px);
                }

                & > .metismenu-item > .metismenu-link {
                    height: 2.3em;
                    line-height: 2.3em;
                    margin: 0 0 3px;

                    &:hover {
                        color: black !important;
                    }

                    .metismenu-icon {
                        display: none;
                    }
                }
            }

            .metismenu-item > .metismenu-link {
                height: 0;
                overflow: hidden;
            }

            .metismenu-link {
                padding-left: 1em;
            }
        }
    }
}

.user-contact-us-menu {
    display: flex;
    justify-content: space-between;
    a {
        color: white !important;
    }
}