// Modals

.modal-header,
.modal-footer {
  background: $gray-100;
}

.modal-body {
  background: $white;
}

.modal-content {
  background: transparent;
}

.modal-footer {
  @include border-bottom-radius($border-radius);
  position: sticky;
  bottom: 0;
}

.modal-content {
  box-shadow: $box-shadow-lg-default;
  @include border-radius($border-radius);
}

.modal-form-header {
  background: white;
  border: 0;
  padding: 0.5rem;
}
