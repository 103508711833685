// BOOTSTRAP 4 VARIABLES

@import "components/bootstrap4/functions";
@import "components/bootstrap4/variables";
@import "components/bootstrap4/mixins";

// ArchitectUI Themes Variables

@import "themes/layout-variables";

// BOOTSTRAP 4 IMPORTS

@import "components/bootstrap4/root";
@import "components/bootstrap4/reboot";
@import "components/bootstrap4/type";
@import "components/bootstrap4/images";
@import "components/bootstrap4/code";
@import "components/bootstrap4/grid";
@import "components/bootstrap4/tables";
@import "components/bootstrap4/forms";
@import "components/bootstrap4/buttons";
@import "components/bootstrap4/transitions";
@import "components/bootstrap4/dropdown";
@import "components/bootstrap4/button-group";
@import "components/bootstrap4/input-group";
@import "components/bootstrap4/custom-forms";
@import "components/bootstrap4/nav";
@import "components/bootstrap4/navbar";
@import "components/bootstrap4/card";
@import "components/bootstrap4/breadcrumb";
@import "components/bootstrap4/pagination";
@import "components/bootstrap4/badge";
@import "components/bootstrap4/jumbotron";
@import "components/bootstrap4/alert";
@import "components/bootstrap4/progress";
@import "components/bootstrap4/media";
@import "components/bootstrap4/list-group";
@import "components/bootstrap4/close";
@import "components/bootstrap4/toasts";
@import "components/bootstrap4/modal";
@import "components/bootstrap4/tooltip";
@import "components/bootstrap4/popover";
@import "components/bootstrap4/carousel";
@import "components/bootstrap4/spinners";
@import "components/bootstrap4/utilities";
@import "components/bootstrap4/print";
// LAYOUT

@import "layout/layout";

// UTILS

@import "utils/helpers";
@import "utils/backgrounds";
@import "utils/animate";
@import "utils/comps-animations";

// ELEMENTS

@import "elements/buttons";
@import "elements/dropdown";
@import "elements/navs";
@import "elements/badges";
@import "elements/cards";
@import "elements/tabs";
@import "elements/accordions";
@import "elements/modals";
@import "elements/loaders";
@import "elements/progressbar";
@import "elements/timeline";
@import "elements/listgroup";
@import "elements/forms";
@import "elements/pagination";
@import "elements/chat";
@import "elements/forms-wizard";

// DASHBOARD BOXES

@import "widgets/chart-boxes/chart-boxes";
@import "widgets/content-boxes/content-boxes";
@import "widgets/profile-boxes/profile-boxes";

// PAGES

@import "pages/userpages";

// Applications

@import "applications/applications-base";

// COMPONENTS

// Perfect Scrollbar
@import "components/perfect-scrollbar/perfect-scrollbar";

// Loading Buttons
@import "components/loading-buttons/loading-buttons";

// Hamburger Button
@import "components/hamburgers/hamburgers";

// Drawer
@import "components/drawer/drawer";

// Notifications
@import "components/notifications/sweetalerts";
@import "components/notifications/toastify";

// Modals
@import "components/modals/rodal";

// Progress Bar
@import "components/progress-bar/progress-bar";

// Slick Carousel
@import "components/slick-carousel/slick/slick";

// Block loading
@import "components/block-loading/block-loading";

// Popovers & Tooltips
@import "components/popovers-tooltips/popovers-tooltips";

// Sticky Elements
@import "components/sticky-elements/sticky-elements";

// Calendar
@import "components/calendar/calendar";

// Splitters
@import "components/splitters/splitters";

// Tree View
@import "components/tree-view/tree-view";

// Tables
@import "components/tables/tables";

// Icons
@import "components/icons/linearicons";
@import "components/icons/p7icons";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";

// FORMS

@import "components/forms/datepicker/datepicker";
@import "components/forms/rangeslider/rangeslider";
@import "components/forms/multiselect/multiselect";
@import "components/forms/wysiwyg/wysiwyg";
@import "components/forms/toggle-switch/switch";
@import "components/forms/dropzone/dropzone";
@import "components/forms/typeahead/typeahead";

// REACT WIDGETS

@import "components/react-widgets/scss/react-widgets";

// DEMO

@import "demo-ui/demo";

// Responsive

@import "layout/responsive/responsive-base";
$flag-icon-css-path: "~flag-icon-css/flags";
@import "~flag-icon-css/sass/flag-icon.scss";

[data-theme="dark"] {
    .text-white {
        color: $white !important;
    }
    .app-theme-white .app-sidebar {
        background: #21364e;
    }
    .app-sidebar .app-sidebar__inner {
        background-color: #21364e;
    }
    .vertical-nav-menu .metismenu-container {
        &.visible {
            &:before {
                background: #7f94c7;
            }
            & > .metismenu-item > .metismenu-link {
                color: #fff;
            }
        }
        .metismenu-link {
            &.child {
                &:hover {
                    background: #7f94c7 !important;
                    text-decoration: none;
                    transform: scale(1.02);
                }
            }
            &.active {
                color: black !important;
                background-color: #7f94c7 !important;
            }

            &.has-active-child {
                font-weight: 700;
            }

            &.has-active-child,
            &.active {
                color: white;
            }

            &.support-link {
                color: white !important;
            }
        }
    }
    .metismenu-container,
    .metismenu-item {
        margin: 0;
        padding: 0;
        position: relative;
        .menu {
            &.next {
                background: #7f94c7 !important;
            }
            &:hover {
                background: #7f94c7 !important;
                text-decoration: none;
                transform: scale(1.02);
            }
        }
    }
}

[data-theme="light"] {
    .app-sidebar .app-sidebar__inner {
        background-color: #fff;
    }
    .vertical-nav-menu .metismenu-container {
        &.visible {
            &:before {
                background: #7f94c7;
            }
            & > .metismenu-item > .metismenu-link {
                color: $gray-600;
                &.active {
                    color: #545cd8 !important;
                    background: #e0f3ff;
                }
            }
        }
        .metismenu-link {
            &.child {
                &:hover {
                    background: #e0f3ff !important;
                    text-decoration: none;
                    transform: scale(1.02);
                }
            }
            &.has-active-child {
                font-weight: 700;
            }

            &.has-active-child,
            &.active {
                color: #343a40;
            }

            &.active {
                color: #545cd8 !important;
                background: #e0f3ff !important;
            }

            &.support-link {
                color: #343a40 !important;
            }
        }
    }
    .metismenu-container,
    .metismenu-item {
        margin: 0;
        padding: 0;
        position: relative;
        .menu {
            &.next {
                background: #e0f3ff !important;
            }
            &:hover {
                background: #e0f3ff !important;
                text-decoration: none;
                transform: scale(1.02);
            }
        }
    }
}

body {
    overflow: hidden;
}

.app-main .app-main__outer {
    height: calc(100vh - 60px);
    overflow: auto;
}

form label {
    font-weight: bold;
    font-size: 1rem;
    color: $gray-700;
}

input.search {
    font-family: "Font Awesome 5 Free", $font-family-sans-serif;
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    ::placeholder {
        text-align: center;
    }
}

.no-top-radius {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

.no-bottom-radius {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.readOnly {
    .form-control:disabled,
    .custom-select:disabled {
        background-color: #fff;
        color: #495057;
        border-color: #e9ecef;
        &::placeholder {
            color: #495057;
        }
    }

    .SelectField__multi-value__remove {
        display: none;
    }

    .SelectField__multi-value__label {
        padding-right: 6px;
    }

    .SelectField__control.SelectField__control--is-disabled,
    .SelectField__single-value--is-disabled,
    .SelectField--is-disabled {
        background-color: #fff;
        color: #495057;
        border-color: #e9ecef;
        .SelectField__placeholder {
            color: transparent;
        }
    }
}

.modal.isSubmitting {
    &::before {
        position: sticky;
        display: block;
        left: 0;
        content: "Submitting...";
        background: rgba(0, 0, 0, 0.7);
        color: #fff;
        top: 50%;
        width: 100%;
        padding: 1rem;
        text-align: center;
        z-index: 1;
    }
}

.cursor-pointer {
    cursor: pointer;
}

.disabled {
    cursor: auto !important;
}

div.form-control {
    height: auto;
    min-height: calc(1.5em + 0.75rem + 2px);
}

.text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.invReqFilterWidth input {
    width: 100%;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    height: 38px;
    padding: 2px 8px;
}

.value-analysis-cards {
    max-width: 13% !important;
}
