.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #333;
  color: #fff;
  padding: 15px;
  text-align: center;
  z-index: 1000;
}

.cookie-consent-banner button {
  margin: 0 10px;
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
}

.cookie-consent-banner button:hover {
  background-color: #0056b3;
}
