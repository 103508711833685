.FileDrop {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 2px dashed #ced4da;
  border-radius: 0.25rem;
  background-color: #eee;
  color: #878b8f;
  outline: none;
  transition: border 0.2s ease-in-out;

  &:hover {
    border-color: #ced4da;
  }

  &:focus {
    border-color: transparent;
    box-shadow: 0 0 0 0.2rem rgba(84, 92, 216, 0.25);
  }

  &.disabled {
    opacity: 0.5;
  }

  &.hasImagePreview {
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    p {
      display: none;
    }
  }

  p {
    text-align: center;
    user-select: none;
    margin: 0;
  }
}
