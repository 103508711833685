// User Pages

.app-logo {
  height: $logo-height;
  width: $logo-width;
  background: url('../utils/images/logo-inverse.png');
  background-size: contain !important;
}

.app-logo-inverse {
  height: $logo-height;
  width: $logo-width;
  background: url('../utils/images/logo.png');
  background-size: contain !important;
}

.app-login-box {
  .app-logo {
    margin-bottom: $layout-spacer-lg;
  }

  h4 {
    margin-bottom: $layout-spacer-x;
    font-weight: normal;

    div {
      opacity: 0.6;
    }

    span {
      font-size: $font-size-lg;
    }
  }
}
