.custom-checkbox.big {
  padding-left: 2rem;
  .custom-control-label {
    line-height: 1.9rem;
    &::before,
    &::after {
      width: 1.5rem;
      height: 1.5rem;
      left: -2rem;
    }
  }
}
