// Intended to mimic the default Bootstrap style, when moving away from Bootstrap, this should be changed
.SelectField {
    transition: all 0.2s;

    .SelectField__group-heading {
        font-size: medium;
        color: #545cd8;
        text-transform: none;
        font-weight: bold;
    }
    .SelectField__control {
        height: auto;
        max-height: 75px;
        overflow: auto;
        padding: 0;
        border-radius: 0.25rem;
        &:hover {
            border-color: #ced4da;
        }
        &--is-focused {
            color: #495057;
            background-color: #fff;
            border-color: #bcbff0;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgba(84, 92, 216, 0.25);
            &:hover {
                border-color: #bcbff0;
            }
        }
        &--is-disabled {
            color: #6c757d;
            background-color: #e9ecef;
            border-color: #ced4da;

            .SelectField__placeholder,
            .SelectField__dropdown-indicator {
                color: #6c757d;
            }
        }
    }

    .SelectField__menu {
        z-index: 100000000;
    }

    &.is-invalid {
        .SelectField__control {
            border-color: #d92550;
            &--is-focused,
            &:hover {
                border-color: #d92550;
                box-shadow: 0 0 0 0.2rem rgba(217, 37, 80, 0.25);
            }
        }
        ~ .invalid-feedback,
        ~ .invalid-tooltip {
            display: block;
        }
    }
}
