.animated-bounce {
    animation: bounce 0.3s ease;
}

@keyframes bounce {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.3);
    }
    100% {
        transform: scale(1);
    }
}

.star-icon {
    font-size: 20px;
    color: #e0e0e0;
    transition: color 0.3s ease, transform 0.3s ease;
    cursor: pointer;
    position: relative;
}

.star-icon.active {
    color: #ffbf00;
    text-shadow: 0 0 5px rgba(255, 191, 0, 0.5), 0 0 10px rgba(255, 191, 0, 0.5);
}

.star-icon:hover {
    transform: scale(1.3);
    animation: glowing 0.5s infinite alternate;
}

.star-icon:active {
    animation: bounce 0.3s ease;
    transform: scale(1.5);
}

@keyframes glowing {
    0% {
        text-shadow: 0 0 5px rgba(255, 191, 0, 0.5), 0 0 10px rgba(255, 191, 0, 0.5);
    }
    100% {
        text-shadow: 0 0 20px rgba(255, 191, 0, 1), 0 0 30px rgba(255, 191, 0, 1);
    }
}

@keyframes bounce {
    0% {
        transform: scale(1.2);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1.3);
    }
}
